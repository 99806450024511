import * as React from 'react';

interface Props {
  onLogoutClick: (event) => void;
  history: any;
}

export const LogOut: React.FunctionComponent<Props> = props => {
  return (
    <a className="dropdown-item" href="" onClick={props.onLogoutClick}>
      Log out
    </a>
  );
};
