import * as React from 'react';
import { Component } from 'react';
import * as propz from 'propz';
import { History, Location } from 'history';
import { createOrder } from '../../../../services/public/basket';
import { AppOneTimeCode } from '../../../AppOrders/AppOrders';

import './DeliveryOptions.scss';
import { ORDER_DELIVERY_TYPE, DEFAULT_COUNTRY_OPTION_VALUE } from '../../../../consts/order';
import { PAYMENT_CHANNEL, BUYER_TYPE, PAY360_RESULT } from '../../../../consts/payment';
import { getConfig } from '../../../../services/public/config';
import { getBasket } from '../../../../services/public/basket';
import { getSchoolsFromBasketImagesUniq } from '../../../../helpers/delivery';
import { getCallback, getOneTimeCodeCallbackC, getOneTimeCodeCallbackY } from '../../../../helpers/worldpay';
import { PublicConfig } from '../../../../models/config';
import { Loader } from '../../../../components/Loader/Loader';
import { payment360 } from '../../../../services/public/payment360';
import { SimpleModal } from '../../../../components/SimpleModal/SimpleModal';

interface Props {
  history: History;
  location: Location;
  oneTimeCode: AppOneTimeCode;
  deliveryFromPrevSubmit: any;
}

interface State {
  schools: { schoolId: string; schoolName: string; schoolCode: string }[];
  studentName: string;
  studentClassName: string;
  selectedSchoolId: string;
  phone: string;
  email: string;
  order: any;
  config: PublicConfig;
  isLoading: boolean;
  isDisablePaymentButton: boolean;

  iFrameSrc: string | undefined;
  sessionId: string | undefined;

  isPaymentUnavailable: boolean;
}

export class SchoolDeliveryForm extends Component<Props, State> {
  formRef: any;
  iframeRef: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      schools: [],
      studentName: '',
      studentClassName: '',
      selectedSchoolId: '',
      phone: '',
      email: '',
      order: undefined,
      config: undefined,
      isLoading: true,
      isDisablePaymentButton: false,
      iFrameSrc: undefined,
      sessionId: undefined,
      isPaymentUnavailable: false,
    };

    this.formRef = React.createRef();
    this.iframeRef = React.createRef();
  }

  componentDidMount() {
    const { oneTimeCode, deliveryFromPrevSubmit } = this.props;

    window.addEventListener('message', this.payment);
    let config: PublicConfig;

    this.setState({
      isLoading: true,
    });

    const getBasketPromise = getBasket(oneTimeCode);
    const getConfigPromise = getConfig();

    const promises = [getBasketPromise, getConfigPromise];

    Promise.all(promises).then(([basket, config]) => {
      const schools = getSchoolsFromBasketImagesUniq(basket.items);
      const selectedSchoolId = schools.length === 1 ? schools[0].schoolId : '';
      if (typeof deliveryFromPrevSubmit !== 'undefined') {
        const { school, home } = deliveryFromPrevSubmit;

        const isSchool = typeof school !== 'undefined';
        const isHome = typeof home !== 'undefined';

        switch (true) {
          case isSchool:
            const { studentName, studentClassName, phone, email } = school;
            this.setState({
              schools,
              selectedSchoolId,
              studentName,
              studentClassName,
              phone,
              email,
              config,
              isLoading: false,
            });
            break;
          case isHome:
            const { phone: phoneFromHome, email: emailFromHome } = home;
            this.setState({
              schools,
              selectedSchoolId,
              phone: phoneFromHome,
              email: emailFromHome,
              config,
              isLoading: false,
            });
            break;
        }
      } else {
        this.setState({
          schools,
          selectedSchoolId,
          config,
          isLoading: false,
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.payment);
  }

  payment = event => {
    const { data } = event;

    const isDataPaid = data === PAY360_RESULT.PAID;
    const isDataCanceled = data === PAY360_RESULT.CANCELED;
    const isDataTimeout = data === PAY360_RESULT.TIMEOUT;
    if (isDataPaid || isDataCanceled || isDataTimeout) {
      this.props.history.push(`/oneTimeCode?status=${data}`);
    }
  };

  onFormSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();
    const { oneTimeCode } = this.props;
    const { studentName, studentClassName, phone, email, selectedSchoolId, schools } = this.state;
    let order;

    this.setState({
      isLoading: true,
      isDisablePaymentButton: true,
    });

    const school = schools.find(schoolState => schoolState.schoolId === selectedSchoolId);

    const data = {
      schoolDelivery: {
        studentName: studentName,
        studentForm: studentClassName,
        schoolId: school.schoolId,
        schoolName: school.schoolName,
        schoolCode: school.schoolCode,
      },
      delivery: {
        phone: phone,
        email: email,
      },
      deliveryType: ORDER_DELIVERY_TYPE.SCHOOL,
    };
    createOrder(oneTimeCode, data).then(_order => {
      order = _order;

      const { config } = this.state;
      const { payments } = config;
      const { type } = payments;

      const channel = PAYMENT_CHANNEL.WEB;
      const buyerType = BUYER_TYPE.ONE_TIME_CODE;

      switch (type) {
        case 'worldPay': {
          this.setState(
            {
              order,
              isLoading: false,
            },
            () => {
              this.formRef.current.submit();
            }
          );
          break;
        }

        case 'pay360Hosted': {
          const { id } = order;
          payment360(id, channel, buyerType).then(res => {
            const { redirectUrl } = res;

            if (redirectUrl) {
              window.open(redirectUrl, '_self');
            } else {
              this.setState({
                isLoading: false,
                isPaymentUnavailable: true,
                order,
              });
            }
          });
          break;
        }

        case 'pay360Iframe': {
          const { id } = order;

          payment360(id, channel, buyerType).then(res => {
            const { redirectUrl, sessionId } = res;

            if (redirectUrl && sessionId) {
              this.setState({ isLoading: false, order, iFrameSrc: redirectUrl, sessionId });
            } else {
              this.setState({
                isLoading: false,
                isPaymentUnavailable: true,
                order,
              });
            }
          });
          break;
        }
      }
    });
  };

  onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ phone: value });
  };

  onEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ email: value });
  };

  onStudentNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ studentName: value });
  };

  onStudentClassNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value;
    this.setState({ studentClassName: value });
  };

  onSchoolChange = (event): void => {
    const value = event.target.value;
    this.setState({ selectedSchoolId: value });
  };

  selectSchoolOptions = () => {
    const { schools } = this.state;

    return (
      <>
        {schools.length > 1 && (
          <option value="" key="emptyOption" disabled={true}>
            Select school
          </option>
        )}
        {schools.map(school => {
          return (
            <option value={school.schoolId} key={school.schoolId}>
              {school.schoolName}
            </option>
          );
        })}
      </>
    );
  };

  onCloseErrorClick = () => {
    this.setState({
      isPaymentUnavailable: false,
      isDisablePaymentButton: false,
    });
  };

  renderError(): React.ReactNode {
    const { isPaymentUnavailable } = this.state;

    return (
      <SimpleModal
        isOpen={isPaymentUnavailable}
        title={'Error'}
        body={'Unfortunately, the payment service is currently unavailable. Please try again later'}
        buttonCancelText={'Ok'}
        onCloseClick={this.onCloseErrorClick}
      />
    );
  }

  renderHiddenForm() {
    const { order, config } = this.state;
    const { oneTimeCode } = this.props;
    const { username, password, basketId } = oneTimeCode;
    const { payments } = config;
    const { worldpay } = payments;
    const { formActionUrl, testModeValue, instId, lang, accId1, currency } = worldpay;

    return (
      <form action={formActionUrl} method="POST" ref={this.formRef}>
        <input type="hidden" name="testMode" value={testModeValue} />
        <input type="hidden" name="instId" value={instId} />
        <input type="hidden" name="cartId" value={order.orderNumber} />
        <input type="hidden" name="amount" value={order.orderAmount} />
        <input type="hidden" name="currency" value={currency} />

        <input type="hidden" name="country" value={DEFAULT_COUNTRY_OPTION_VALUE} />
        <input type="hidden" name="email" value={order.delivery.email} />
        <input type="hidden" name="tel" value={order.delivery.phone} />

        <input type="hidden" name="lang" value={lang} />
        <input type="hidden" name="hideCurrency" value="" />
        <input type="hidden" name="noLanguageMenu" value="" />
        <input type="hidden" name="accId1" value={accId1} />

        {/*custom field*/}
        <input type="hidden" name="MC_orderId" value={order.id} />
        <input type="hidden" name="MC_basketId" value={basketId} />
        <input type="hidden" name="MC_username" value={username} />
        <input type="hidden" name="MC_password" value={password} />
        <input type="hidden" name="MC_callbackY" value={getOneTimeCodeCallbackY()} />
        <input type="hidden" name="MC_callbackC" value={getOneTimeCodeCallbackC()} />
        <input type="hidden" name="MC_callback" value={getCallback()} />
        <input type="hidden" name="MC_mobileApp" value={'no'} />
      </form>
    );
  }

  renderHiddenIframe() {
    const { iFrameSrc } = this.state;
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <iframe src={iFrameSrc} width="100%" style={{ border: 'none' }} height="1300px" ref={this.iframeRef} />
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      studentName,
      studentClassName,
      order,
      selectedSchoolId,
      phone,
      email,
      isLoading,
      isDisablePaymentButton,
      iFrameSrc,
      config,
      isPaymentUnavailable,
    } = this.state;

    const type = propz.get(config, ['payments', 'type'], 'worldPay');

    if (isLoading) {
      return <Loader />;
    }

    const classes = isPaymentUnavailable ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderError()}
        {typeof order !== 'undefined' && type === 'worldPay' && this.renderHiddenForm()}
        {typeof iFrameSrc !== 'undefined' && this.renderHiddenIframe()}
        {typeof iFrameSrc === 'undefined' && (
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-xl-12">
                <div className="bSchoolDeliveryForm">
                  <div className={'eSchoolDeliveryFormTitle'}>SCHOOL DELIVERY</div>
                  <div className="row justify-content-center">
                    <form onSubmit={this.onFormSubmit} className="">
                      <div className="eSchoolDeliveryFormInputWrapper">
                        <div className="eDeliveryFormContainer mPaddingTop20">
                          <div className="eSchoolDeliveryFormText">Delivery Details</div>
                        </div>
                        <div>
                          <select
                            id="selectIdCountry"
                            key="schoolDeliveryForm_country"
                            className="eDeliveryFormSelect mMarginTop30"
                            onChange={event => this.onSchoolChange(event)}
                            value={selectedSchoolId}
                            required
                          >
                            {this.selectSchoolOptions()}
                          </select>
                        </div>
                        <div>
                          <input
                            type="text"
                            className="eDeliveryFormInput mMarginTop30"
                            placeholder="Student's name"
                            value={studentName}
                            onChange={this.onStudentNameChange}
                            required
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            className="eDeliveryFormInput"
                            placeholder="Class name"
                            value={studentClassName}
                            onChange={this.onStudentClassNameChange}
                            required
                          />
                        </div>
                        <div>
                          <input
                            type="text"
                            className="eDeliveryFormInput"
                            placeholder="Contact number"
                            value={phone}
                            onChange={this.onPhoneChange}
                            key="deliveryForm_phone"
                            required
                          />
                        </div>
                        <div>
                          <input
                            type="email"
                            className="eDeliveryFormInput"
                            placeholder="Email"
                            value={email}
                            onChange={this.onEmailChange}
                            key="schoolDeliveryForm_email"
                            pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                            required
                          />
                        </div>
                      </div>
                      <div className="eDeliveryFormButtonWrapper">
                        <button className="eDeliveryFormButton" type="submit" disabled={isDisablePaymentButton}>
                          Payment
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
