import * as React from 'react';
import * as Yup from 'yup';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Button } from 'Src/components/Button/Button';
import { Switch } from '../../../../components/Switch/Switch';

interface Props {
  onCancel: () => void;
  onSubmit: (data) => void;
  schoolUser?: any;
}

export function SchoolAppSchoolUsersForm2(props: Props) {
  const { schoolUser, onSubmit, onCancel } = props;
  const isUserExist = typeof schoolUser !== 'undefined';

  const UserSchema = isUserExist
    ? Yup.object().shape({
        email: Yup.string()
          .email('Must be valid email')
          .required('Required'),
        mobilePhone: Yup.string(),
        firstName: Yup.string(),
        lastName: Yup.string(),
      })
    : Yup.object().shape({
        email: Yup.string()
          .email('Must be valid email')
          .required('Required'),
        password: Yup.string().required('Required'),
        mobilePhone: Yup.string(),
        firstName: Yup.string(),
        lastName: Yup.string(),
      });

  const userForm = {
    email: isUserExist ? schoolUser.email : '',
    mobilePhone: isUserExist ? schoolUser.mobilePhone : '',
    firstName: isUserExist ? schoolUser.firstName : '',
    lastName: isUserExist ? schoolUser.lastName : '',
    isDataNotificationEnabled: isUserExist ? schoolUser.isDataNotificationEnabled : false,
    isSchoolAdmin: isUserExist ? schoolUser.isSchoolAdmin : false,
    password: '',
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12">
          <Formik
            initialValues={userForm}
            validationSchema={UserSchema}
            onSubmit={values => {
              if (!values.mobilePhone) {
                delete values.mobilePhone;
              }
              const { password, ...rest } = values;
              if (password === '') {
                onSubmit(rest);
              } else {
                onSubmit(values);
              }
            }}
          >
            {({ touched, errors, handleSubmit }) => (
              <Form>
                <div className="form-group form-check">
                  <div className="form-group">
                    <label>Email</label>
                    <Field name="email" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="email" />
                  </div>

                  <div className="form-group">
                    <label>Phone</label>
                    <Field name="mobilePhone" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="mobilePhone" />
                  </div>

                  <div className="form-group">
                    <label>First name</label>
                    <Field name="firstName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="firstName" />
                  </div>

                  <div className="form-group">
                    <label>Last name</label>
                    <Field name="lastName" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="lastName" />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <Field name="password" className="form-control mb-3" />
                    <ErrorMessage component="div" className="alert alert-danger" name="password" />
                  </div>

                  <Field name="isDataNotificationEnabled">
                    {({ field }) => <Switch {...field} text={'Notifications'} customClass="mb-3" />}
                  </Field>

                  <Field name="isSchoolAdmin">
                    {({ field }) => <Switch {...field} text={'School admin'} customClass="mb-3" />}
                  </Field>

                  <Button onClick={onCancel} text={'Cancel'} customClass={'mt-3 mb-3 mr-3 btn-secondary'} />
                  <Button
                    onClick={handleSubmit}
                    text={'Save'}
                    customClass={'mt-3 mb-3 btn btn-primary'}
                    type={'submit'}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
