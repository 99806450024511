import * as React from 'react';
import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
import { SimpleSidebarItem2 } from './SchoolAppSidebar2';

interface Props {
  item: SimpleSidebarItem2;
}

export const SchoolAppSidebarItem2: FunctionComponent<Props> = props => {
  const { itemTitle, to } = props.item;

  return (
    <NavLink activeStyle={{ fontWeight: 'bold', color: '#007bff' }} className={'nav-link eSidebarItem'} to={to}>
      {itemTitle}
    </NavLink>
  );
};

SchoolAppSidebarItem2.displayName = 'SchoolAppSidebarItem2';
