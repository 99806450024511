import * as React from 'react';
import { Component } from 'react';
import { SchoolAppSidebarItem2 } from './SchoolAppSidebarItem2';
import { AppSchoolsUser2 } from '../AppSchools2';
import { getSchoolUserSchool, getSchoolUserProfile } from '../../../services/schoolUser/schools';

interface Props {
  user: AppSchoolsUser2;
}

interface State {
  schoolName: string;
  userFirstName: string;
  userLastName: string;
}

export interface SimpleSidebarItem2 {
  to: {
    pathname: string;
  };
  itemTitle: string;
}

export class SchoolAppSidebar2 extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: '',
      userFirstName: '',
      userLastName: '',
    };
  }

  componentDidMount() {
    const { user } = this.props;
    getSchoolUserSchool(user).then(school => {
      this.setState({ schoolName: school.name });
    });

    getSchoolUserProfile(user).then(profile => {
      this.setState({
        userFirstName: profile.firstName,
        userLastName: profile.lastName,
      });
    });
  }

  getSidebarItems(): SimpleSidebarItem2[] {
    const { user } = this.props;
    const { isSchoolAdmin } = user;
    return isSchoolAdmin
      ? [
          {
            itemTitle: 'Dashboard',
            to: { pathname: '/dashboard' },
          },
          {
            itemTitle: 'Establishment Information',
            to: { pathname: '/schoolInformation' },
          },
          {
            itemTitle: 'Users',
            to: { pathname: '/users' },
          },
          {
            itemTitle: 'Bookings',
            to: { pathname: '/upload/bookings' },
          },
          {
            itemTitle: 'Images for Checking',
            to: { pathname: '/schoolJobs' },
          },
          {
            itemTitle: 'Images Available to Download',
            to: { pathname: '/upload/downloads' },
          },
          {
            itemTitle: 'Links & Logins for Parents',
            to: { pathname: '/upload/links' },
          },
          {
            itemTitle: 'PDF Documents',
            to: { pathname: '/upload/pdf' },
          },
          /*          {
            itemTitle: 'Notifications',
            to: { pathname: '/notifications' },
          },*/
        ]
      : [
          {
            itemTitle: 'Dashboard',
            to: { pathname: '/dashboard' },
          },
          {
            itemTitle: 'Bookings',
            to: { pathname: '/upload/bookings' },
          },
          {
            itemTitle: 'Images Available to Download',
            to: { pathname: '/upload/downloads' },
          },
          {
            itemTitle: 'Links & Logins for Parents',
            to: { pathname: '/upload/links' },
          },
          {
            itemTitle: 'PDF Documents',
            to: { pathname: '/upload/pdf' },
          },
        ];
  }

  render() {
    const { schoolName, userFirstName, userLastName } = this.state;
    const sidebarItems: SimpleSidebarItem2[] = this.getSidebarItems();
    return (
      <div className="bSchoolAppSidebar2">
        <div className="sidebar2ContentWrapper">
          {' '}
          <div className="schoolAppSidebar2Header">
            <div className="schoolName">{schoolName}</div>
            <div className="userInfo">
              <img className="userIcon" src="/dist/images/icon/schoolUser.svg" alt="User Icon" />
              <div className="loggedInAs">
                <div className="loggedText">Logged in as:</div>
                <div className="userName">
                  {userFirstName} {userLastName}
                </div>
              </div>
            </div>
          </div>
          <div className="schoolAppSidebar2Item">
            {sidebarItems.map((sidebarItem, index) => (
              <SchoolAppSidebarItem2 key={`sidebar_item_${index}`} item={sidebarItem} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
