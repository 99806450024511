import { Component } from 'react';
import { Loader } from '../../components/Loader/Loader';
import * as React from 'react';
import * as propz from 'propz';
import { clearSchoolUserSession, getSessionForSchoolUser } from '../../helpers/session';
import { getSchoolUserProfile, getSchoolUserSchool, logoutSchoolUser } from '../../services/schoolUser/schools';
import { Redirect, Route, Switch } from 'react-router';
import { SchoolAppLogin2 } from './SchoolAppLogin2/SchoolAppLogin2';
import { SchoolAppGenericView2 } from './SchoolAppGenericView2/SchoolAppGenericView2';

interface State {
  user: AppSchoolsUser2;
  isLoading: boolean;
}

export interface AppSchoolsUser2 {
  authorized: boolean;
  userId: string;
  sessionKey: string;
  activeSchool: any;
  isSchoolAdmin: boolean;
}

export class AppSchools2 extends Component<{}, State> {
  constructor(props) {
    super(props);

    const { session } = getSessionForSchoolUser();
    const isSession = typeof session !== 'undefined';

    this.state = {
      user: {
        authorized: isSession,
        userId: propz.get(session, ['userId']),
        sessionKey: propz.get(session, ['key']),
        activeSchool: undefined,
        isSchoolAdmin: false,
      },
      isLoading: true,
    };
  }

  componentDidMount() {
    const { user } = this.state;
    const { sessionKey } = user;

    if (typeof sessionKey !== 'undefined') {
      this.setState({
        isLoading: true,
      });

      let isSchoolAdmin;

      getSchoolUserProfile(user)
        .then(schoolUser => {
          isSchoolAdmin = schoolUser.isSchoolAdmin;
          return getSchoolUserSchool(user);
        })
        .then(school => {
          this.setState({
            user: {
              ...user,
              activeSchool: school,
              isSchoolAdmin: isSchoolAdmin,
            },
            isLoading: false,
          });
        });
    } else {
      this.setState({
        isLoading: false,
      });
    }
  }

  setDefaultState = (): void => {
    clearSchoolUserSession();

    this.setState({
      user: {
        sessionKey: undefined,
        userId: undefined,
        authorized: false,
        activeSchool: undefined,
        isSchoolAdmin: false,
      },
    });
  };

  onLogoutClick = (event): void => {
    event.preventDefault();

    const { user } = this.state;
    const { sessionKey } = user;
    logoutSchoolUser(sessionKey).then(
      () => {
        this.setDefaultState();
      },
      () => {
        this.setDefaultState();
      }
    );
  };

  onLoginClick = (userId: string, key: string): void => {
    const { user } = this.state;

    this.setState({
      isLoading: true,
    });

    let isSchoolAdmin;

    getSchoolUserProfile(user, key)
      .then(schoolUser => {
        isSchoolAdmin = schoolUser.isSchoolAdmin;
        return getSchoolUserSchool(user, key);
      })
      .then(school => {
        this.setState({
          user: {
            ...user,
            authorized: true,
            userId: userId,
            sessionKey: key,
            activeSchool: school,
            isSchoolAdmin: isSchoolAdmin,
          },
          isLoading: false,
        });
      });
  };

  render() {
    const { isLoading, user } = this.state;
    const { authorized } = user;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <Switch>
        <Route
          exact
          path="/login"
          component={props => <SchoolAppLogin2 onFormSubmit={this.onLoginClick} {...props} />}
        />

        <Route
          path="/"
          render={props =>
            authorized ? (
              <SchoolAppGenericView2 onLogoutClick={this.onLogoutClick} user={user} {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      </Switch>
    );
  }
}
