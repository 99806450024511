import * as React from 'react';
import { SchoolAppPermissionSelector2 } from '../SchoolAppPermissionSelector2/SchoolAppPermissionSelector2';
import { AppSchoolsUser2 } from '../AppSchools2';

interface Props {
  user: AppSchoolsUser2;
  onLogoutClick: (event) => void;
  history: any;
}

export const SchoolAppTopNavigation2: React.FunctionComponent<Props> = props => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <SchoolAppPermissionSelector2 {...props} />
    </nav>
  );
};

SchoolAppTopNavigation2.displayName = 'SchoolAppTopNavigation2';
