import * as React from 'react';
import * as classNames from 'classnames';
import { LogOut } from 'Src/components/LogOut/LogOut';
import { AppSchoolsUser2 } from '../AppSchools2';

interface State {
  isOpen: boolean;
}

interface Props {
  user: AppSchoolsUser2;
  history: any;
  onLogoutClick: (event) => void;
}

export class SchoolAppPermissionSelector2 extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  onSelectorClick = event => {
    event.preventDefault();
    const { isOpen } = this.state;

    this.setState({
      isOpen: !isOpen,
    });
  };

  render() {
    const { isOpen } = this.state;
    const { onLogoutClick, history, user } = this.props;
    const { activeSchool } = user;
    const { name } = activeSchool;

    const dropdownClasses = classNames({
      dropdown: true,
      'nav-item': true,
      show: isOpen,
    });

    const dropdownMenuClasses = classNames({
      'dropdown-menu': true,
      show: isOpen,
    });

    return (
      <div className="collapse navbar-collapse">
        <ul className="navbar-nav mr-auto">
          <li className={dropdownClasses}>
            <a className="nav-link dropdown-toggle" href="" onClick={this.onSelectorClick}>
              {name}
            </a>
            <div className={dropdownMenuClasses}>
              <LogOut onLogoutClick={onLogoutClick} history={history} />
            </div>
          </li>
        </ul>
      </div>
    );
  }
}
