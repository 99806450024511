export const MENU_ITEMS: { [prop: string]: string } = {
  USERS: 'USERS',
  SCHOOL_TYPES: 'SCHOOL_TYPES',
  SCHOOLS: 'SCHOOLS',
  PHOTO_TYPES: 'PHOTO_TYPES',
  PRODUCT_TYPES: 'PRODUCT_TYPES',
  PRODUCTS: 'PRODUCTS',
  PACKAGES: 'PACKAGES',
  OFFERS: 'OFFERS',
  DELIVERY_CHARGES: 'DELIVERY_CHARGES',
  PRE_REGISTRATIONS: 'PRE_REGISTRATIONS',
  JOBS: 'JOBS',
  ORDERS: 'ORDERS',
  ALL_ORDER_IMAGES: 'ALL_ORDER_IMAGES',
  VOUCHERS: 'VOUCHERS',
  IMAGES: 'IMAGES',
  CUSTOMERS: 'CUSTOMERS',
  SLIDERS: 'SLIDERS',
  INTEGRATION_LOGS: 'INTEGRATION_LOGS',
  CUSTOMER_WITH_STUDENTS: 'CUSTOMER_WITH_STUDENTS',
  UNSUBSCRIBED_EMAILS: 'UNSUBSCRIBED_EMAILS',
  DATA_EXCHANGE: 'DATA_EXCHANGE',
  SCHOOL_JOBS: 'SCHOOL_JOBS',
};

export const MENU_ITEMS_SERVER_TO_CLIENT_MAPPING = {
  USERS: 'Users',
  SCHOOL_TYPES: 'School types',
  SCHOOLS: 'Schools',
  PHOTO_TYPES: 'Photo types',
  PRODUCT_TYPES: 'Product types',
  PRODUCTS: 'Products',
  PACKAGES: 'Packages',
  DELIVERY_CHARGES: 'Delivery charges',
  PRE_REGISTRATIONS: 'Pre registrations',
  JOBS: 'Jobs',
  OFFERS: 'Offers',
  ORDERS: 'Orders',
  ALL_ORDER_IMAGES: 'All order images',
  VOUCHERS: 'Vouchers',
  IMAGES: 'All images',
  CUSTOMERS: 'Customers',
  SLIDERS: 'Promotions',
  INTEGRATION_LOGS: 'Integration logs',
  CUSTOMER_WITH_STUDENTS: 'Customers linking',
  UNSUBSCRIBED_EMAILS: 'Unsubscribed emails',
  DATA_EXCHANGE: 'Data exchange',
  SCHOOL_JOBS: 'School jobs',
};
