import axios from 'axios';
import { AppSchoolsUser2 } from '../../views/AppSchools2/AppSchools2';
import { AppUser } from '../../views/App/App';
import { ALL_LIMIT } from '../../consts/table';

export function getSchoolUserSchool(schoolUser: AppSchoolsUser2, optKey?: string) {
  const { sessionKey: key } = schoolUser;

  const config = optKey
    ? {
        headers: { usid: optKey },
      }
    : {
        headers: { usid: key },
      };

  return axios.get(`${window.apiBase}/schoolUser/schools`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUserProfile(schoolUser: AppSchoolsUser2, optKey?: string) {
  const { sessionKey: key } = schoolUser;

  const config = optKey
    ? {
        headers: { usid: optKey },
      }
    : {
        headers: { usid: key },
      };

  return axios.get(`${window.apiBase}/schoolUser/profile`, config).then(response => {
    return response.data;
  });
}

export function logoutSchoolUser(sessionKey) {
  const config = {
    headers: { usid: sessionKey },
  };

  return axios.delete(`${window.apiBase}/i/sessions/${sessionKey}`, config);
}

export function loginSchoolUser(email: string, password: string) {
  return axios.post(`${window.apiBase}/i/loginSchoolUser`, { email, password }, {}).then(response => {
    return response.data;
  });
}

export function getBucketConfigBP(schoolUser: AppSchoolsUser2) {
  const { sessionKey: key } = schoolUser;

  const config = {
    headers: {
      usid: key,
    },
  };

  return axios.get(`${window.apiBase}/schoolUser/newConfig`, config).then(response => {
    return response.data;
  });
}

export function sendUploadFileNotification(schoolUser: AppSchoolsUser2, fileName: string[]) {
  const { sessionKey: key } = schoolUser;

  const config = {
    headers: {
      usid: key,
    },
  };

  return axios.post(`${window.apiBase}/schoolUser/uploadFile`, { fileName }, config).then(response => {
    return response.data;
  });
}

export function getSchoolUserSchoolUsers(user: AppSchoolsUser2, filter) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
    params: {
      filter,
    },
  };

  return axios.get(`${window.apiBase}/schoolUser/schoolUsers`, config).then(response => {
    return response.data;
  });
}

export function getAllSchoolUserSchoolUsers(user: AppSchoolsUser2, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: {
            ...where,
          },
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          limit: ALL_LIMIT,
          skip: 0,
        },
      },
    };
  }

  return axios.get(`${window.apiBase}/schoolUser/schoolUsers`, config).then(response => {
    return response.data;
  });
}

export function getSchoolUserSchoolUsersCount(user: AppSchoolsUser2, where?) {
  const { sessionKey: key } = user;

  let config;

  if (typeof where !== 'undefined') {
    config = {
      headers: { usid: key },
      params: {
        filter: {
          where: where,
        },
      },
    };
  } else {
    config = {
      headers: { usid: key },
    };
  }

  return axios.get(`${window.apiBase}/schoolUser/schoolUsers/count`, config).then(response => {
    return response.data;
  });
}

export function addSchoolUserSchoolUsers(user: AppSchoolsUser2, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.post(`${window.apiBase}/schoolUser/schoolUsers`, data, config).then(response => {
    return response.data;
  });
}

export function updateSchoolUserSchoolUser(user: AppSchoolsUser2, schoolUserId: string, data) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.put(`${window.apiBase}/schoolUser/schoolUsers/${schoolUserId}`, data, config).then(response => {
    return response.data;
  });
}

export function deleteSchoolUserSchoolUser(user: AppSchoolsUser2, schoolUserId: string) {
  const { sessionKey: key } = user;

  const config = {
    headers: { usid: key },
  };

  return axios.delete(`${window.apiBase}/schoolUser/schoolUsers/${schoolUserId}`, config).then(response => {
    return response.data;
  });
}
