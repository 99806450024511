import * as React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';

interface Props {
  onUsernameChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onPasswordChange: (event: React.FormEvent<HTMLInputElement>) => void;
  onFormSubmit: (event: React.SyntheticEvent) => void;
  username: string;
  password: string;
  textError: string;
  onSignUpClick: () => void;
}

export function LoginFormOrders(props: Props) {
  const { onUsernameChange, onPasswordChange, onFormSubmit, username, password, textError, onSignUpClick } = props;
  const [showAccountDeletedModal, setShowAccountDeletedModal] = React.useState(false);
  const location = useLocation();
  React.useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('accountDeleted') === 'true') {
      setShowAccountDeletedModal(true);
    }
  }, [location.search]);

  const closeAccountDeletedModal = () => {
    setShowAccountDeletedModal(false);
  };

  const renderAccountDeletedModal = () => {
    if (!showAccountDeletedModal) return null;
    return (
      <SimpleModal
        isOpen={showAccountDeletedModal}
        title=""
        body="Your account has been deleted."
        buttonCancelText="Ok"
        onCloseClick={closeAccountDeletedModal}
      />
    );
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12">
          <div className="bLoginFormOrders">
            <form onSubmit={onFormSubmit}>
              <div className="eLoginFormOrdersTitle">HELLO!</div>
              <div className="eLoginFormOrdersText">Let's get started, please enter your login details</div>
              {textError && <div className="alert alert-danger mLoginOrdersError">{textError}</div>}
              <div className="eLoginFormOrdersInputWrapper">
                <div>
                  <input
                    type="text"
                    className="eLoginFormOrdersInput"
                    placeholder="Email address"
                    value={username}
                    onChange={onUsernameChange}
                  />
                </div>
                <div>
                  <input
                    type="password"
                    className="eLoginFormOrdersInput"
                    placeholder="Personal password"
                    value={password}
                    onChange={onPasswordChange}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <button className="eLoginFormOrdersButton mr-3" type="submit">
                  Sign in
                </button>
                <button className={'eLoginFormOrdersButton'} onClick={onSignUpClick}>
                  Sign up
                </button>
              </div>
              <div>
                <Link className={'nav-link eLoginFormResetPassword'} to={{ pathname: '/resetPassword' }}>
                  Forgot password
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
      {renderAccountDeletedModal()}
    </div>
  );
}
