import * as React from 'react';
import { Component } from 'react';
import './SchoolAppUploadFilesView2.scss';
import { AppSchoolsUser2 } from '../AppSchools2';
import { saveAs } from 'file-saver';
import { getBucketConfigBP, sendUploadFileNotification } from '../../../services/schoolUser/schools';
import * as propz from 'propz';
import * as AWS from 'aws-sdk';
import { Loader } from '../../../components/Loader/Loader';
import { SimpleModal } from '../../../components/SimpleModal/SimpleModal';
import * as Moment from 'moment';
import { DATE_TIME_FORMAT } from '../../../consts/consts';
import { Button } from '../../../components/Button/Button';
import { ActionsButton } from '../../../components/Grid/ActionsButton/ActionsButton';
import Dropzone from 'react-dropzone';
import * as JSZip from 'jszip';
import * as BPromise from 'bluebird';
import { SchoolAppUploadFilesViewForm2 } from '../SchoolAppUploadFilesViewForm2/SchoolAppUploadFilesViewForm2';

interface Props {
  user: AppSchoolsUser2;
  history: History;
}

interface State {
  bucketName: string;
  bucketRegion: string;
  identityPoolId: string;
  isLoading: boolean;
  albumsAndPhotos: any[];
  activeAlbum: any;
  activePrefix: string;
  isCreateDirFormModalOpen: boolean;
  inDropZone: boolean;
  uploading: boolean;
  isAdminToHaveSchool: boolean;
  isConfirmationModalOpen: boolean;
  isInfoModalOpen: boolean;
  infoMessage: string;
  activeItemIndex: number;
  accessKeyId: string;
  secretAccessKey: string;
  sessionToken: string;
}

const BUCKET_DATA_TYPE = {
  ALBUM: 'album',
  FILE: 'file',
};

export class SchoolAppUploadFilesView2 extends Component<Props, State> {
  myRefUploadFile: any;
  myRefUploadFolder: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      bucketName: '',
      bucketRegion: '',
      identityPoolId: '',
      isLoading: true,
      albumsAndPhotos: [],
      activeAlbum: undefined,
      activePrefix: '',
      isCreateDirFormModalOpen: false,
      inDropZone: false,
      uploading: false,
      isAdminToHaveSchool: true,
      isConfirmationModalOpen: false,
      isInfoModalOpen: false,
      infoMessage: '',
      activeItemIndex: 0,
      accessKeyId: '',
      secretAccessKey: '',
      sessionToken: '',
    };
    this.myRefUploadFile = React.createRef();
    this.myRefUploadFolder = React.createRef();
  }

  componentDidMount() {
    const { user } = this.props;

    this.setState({
      isLoading: true,
    });

    getBucketConfigBP(user).then(config => {
      const {
        bucketName,
        bucketRegion,
        AccessKeyId: accessKeyId,
        SecretKey: secretAccessKey,
        SessionToken: sessionToken,
      } = config;

      this.setState({
        accessKeyId,
        secretAccessKey,
        sessionToken,
        bucketName,
        bucketRegion,
      });

      const activeSchoolName = propz.get(user, ['activeSchool', 'name'], '');
      this.getFullAlbums(`${activeSchoolName}/`);
    });
  }

  getBucketCredentials() {
    const { bucketName, bucketRegion, accessKeyId, secretAccessKey, sessionToken } = this.state;

    AWS.config.update({
      region: bucketRegion,
    });

    const credentials = new AWS.Credentials({
      accessKeyId,
      secretAccessKey,
      sessionToken,
    });

    AWS.config.credentials = credentials;

    const s3 = new AWS.S3({
      params: { Bucket: bucketName },
    });

    return s3;
  }

  isAlbum = name => {
    return name[name.length - 1] === '/';
  };

  showSendingInfoModal = text => {
    this.setState({
      infoMessage: text,
      isInfoModalOpen: true,
    });
  };

  getFullAlbums(delimiter) {
    const { bucketName } = this.state;
    const s3 = this.getBucketCredentials();
    const isSchoolAccount = true;

    const prefix = isSchoolAccount ? delimiter : '';
    s3.listObjects({ Delimiter: delimiter, Prefix: prefix, Bucket: bucketName }, (err, data) => {
      if (err) {
        this.setState({
          isLoading: false,
        });
        const infoMessage = 'There was an error viewing your album: ' + err.message;
        return this.showSendingInfoModal(infoMessage);
      }

      const contents: any[] = isSchoolAccount ? data.Contents : data.CommonPrefixes;
      const albums = contents
        .map(content => {
          const prefix = isSchoolAccount ? propz.get(data, ['Prefix'], '') : propz.get(content, ['Prefix'], '');
          const albumName = decodeURIComponent(prefix.replace('/', ''));
          const type = this.isAlbum(prefix) ? BUCKET_DATA_TYPE.ALBUM : BUCKET_DATA_TYPE.FILE;
          return {
            name: albumName,
            Key: albumName,
            type: type,
          };
        })
        .sort((contentOne, contentTwo) => {
          if (contentOne.type < contentTwo.type) {
            return -1;
          }
          if (contentOne.type > contentTwo.type) {
            return 1;
          }
        });
      const emptyAlbum = {
        name: '',
        Key: isSchoolAccount ? propz.get(albums[0], ['Key'], '') : '',
        type: BUCKET_DATA_TYPE.ALBUM,
      };
      this.setState({
        albumsAndPhotos: albums,
        activePrefix: data.Prefix,
        activeAlbum: emptyAlbum,
      });

      if (albums.length === 0 && isSchoolAccount) {
        this.setState({
          isAdminToHaveSchool: false,
          isLoading: false,
        });
      } else {
        this.setState({
          isLoading: false,
        });
      }

      if (albums.length > 0 && isSchoolAccount) {
        this.onSchoolAlbumClick(undefined, albums[0], emptyAlbum.Key);
      }
    });
  }

  onSchoolAlbumClick(event, album, activeAlbumKey) {
    if (typeof event !== 'undefined') {
      event.preventDefault();
    }
    const { activeAlbum, bucketName } = this.state;

    const s3 = this.getBucketCredentials();

    this.setState({
      isLoading: true,
    });
    s3.listObjects({ Delimiter: album.Key, Prefix: album.Key, Bucket: bucketName }, (err, data) => {
      if (err) {
        this.setState({
          isLoading: false,
        });
        const infoMessage = 'There was an error viewing your album: ' + err.message;
        return this.showSendingInfoModal(infoMessage);
      }
      let photos = [];
      data.Contents.forEach(content => {
        const key = propz.get(content, ['Key'], '');
        const type = this.isAlbum(key) ? BUCKET_DATA_TYPE.ALBUM : BUCKET_DATA_TYPE.FILE;
        const albumPhotosKey =
          typeof activeAlbum !== 'undefined' && activeAlbum.type === BUCKET_DATA_TYPE.FILE ? key + '/' : key;
        const contentArr = [...albumPhotosKey.split('/')];
        const activeAlbumKeyWithSlash =
          activeAlbumKey[activeAlbumKey.length - 1] !== '/' ? activeAlbumKey + '/' : activeAlbumKey;
        const albumPhotosKeyWithSlash =
          albumPhotosKey[albumPhotosKey.length - 1] !== '/' ? albumPhotosKey + '/' : albumPhotosKey;
        const activeAlbumKeyArr = [...activeAlbumKeyWithSlash.split('/')];
        const albumPhotosKeyArr = [...albumPhotosKeyWithSlash.split('/')];
        const lastPathNameFile = albumPhotosKeyArr[albumPhotosKeyArr.length - 2];

        if (contentArr.length > 0) {
          content['name'] = typeof lastPathNameFile !== 'undefined' ? lastPathNameFile : '';
          if (
            (albumPhotosKey !== activeAlbumKey && contentArr.length === activeAlbumKeyArr.length) ||
            (contentArr.length === activeAlbumKeyArr.length + 1 && contentArr[contentArr.length - 1] === '')
          ) {
            photos.push({
              ...content,
              type: type,
            });
          }
        }
      });
      const sortPhotos = photos
        .sort((contentOne, contentTwo) => {
          if (contentOne.type < contentTwo.type) {
            return -1;
          }
          if (contentOne.type > contentTwo.type) {
            return 1;
          }
        })
        .filter(file => {
          return album.Key + '/' !== file.Key;
        });

      this.setState({
        albumsAndPhotos: sortPhotos,
        activePrefix: data.Prefix,
        activeAlbum: album,
        isLoading: false,
      });
    });
  }

  hideSendingInfoClick = () => {
    this.setState({
      isInfoModalOpen: false,
    });
  };

  renderSendingInfo(): React.ReactNode {
    const { infoMessage, isInfoModalOpen } = this.state;

    return (
      <SimpleModal
        isOpen={isInfoModalOpen}
        title={''}
        body={infoMessage}
        buttonCancelText={'Ok'}
        onCloseClick={this.hideSendingInfoClick}
      />
    );
  }

  onDeleteAlbumClick(album) {
    const albumName = propz.get(album, ['Key'], '');
    const s3 = this.getBucketCredentials();
    let albumKey = encodeURIComponent(albumName) + '/';
    const { activeAlbum, bucketName } = this.state;
    this.setState({
      isLoading: true,
    });
    s3.listObjects({ Prefix: albumName, Bucket: bucketName }, (err, data) => {
      if (err) {
        console.log(err.message);
        this.setState({
          isLoading: false,
        });
        const infoMessage = `There was an error deleting your album: ${err.message}`;
        return this.showSendingInfoModal(infoMessage);
      }
      let objects = data.Contents.map(function(object) {
        return { Key: object.Key };
      });
      s3.deleteObjects(
        {
          Delete: { Objects: objects, Quiet: true },
          Bucket: bucketName,
        },
        (err, data) => {
          if (err) {
            this.setState({
              isLoading: false,
            });
            const infoMessage = `There was an error deleting your album: ${err.message}`;
            return this.showSendingInfoModal(infoMessage);
          }
          this.setState({
            isLoading: false,
          });
          this.setState({
            isLoading: false,
          });
          const infoMessage = 'Folder has been deleted.';
          this.showSendingInfoModal(infoMessage);
          this.onSchoolAlbumClick(undefined, activeAlbum, activeAlbum.Key);
        }
      );
    });
  }

  onDeleteClick = (item, isAlbum) => {
    this.setState({
      isConfirmationModalOpen: false,
    });
    if (isAlbum) {
      this.onDeleteAlbumClick(item);
    } else {
      this.onDeleteFileClick(item);
    }
  };

  onDeleteFileClick(album) {
    const { activeAlbum, bucketName } = this.state;
    const s3 = this.getBucketCredentials();

    s3.deleteObject({ Key: album.Key, Bucket: bucketName }, (err, data) => {
      if (err) {
        const infoMessage = `There was an error deleting your file: ${err.message}`;
        return this.showSendingInfoModal(infoMessage);
      }
      const infoMessage = 'File has been deleted.';
      this.showSendingInfoModal(infoMessage);
      this.onSchoolAlbumClick(undefined, activeAlbum, activeAlbum.Key);
    });
  }

  renderConfirmationModal(): React.ReactNode {
    const { isConfirmationModalOpen, activeItemIndex, albumsAndPhotos } = this.state;
    const item = albumsAndPhotos[activeItemIndex];
    const albumType = albumsAndPhotos.length > 0 ? propz.get(item, ['type'], 'album') : 'album';
    const isAlbum = albumType === BUCKET_DATA_TYPE.ALBUM;
    const infoMessage = isAlbum
      ? 'Folder and its content will be deleted. Are you sure?'
      : 'File will be deleted. Are you sure?';
    return (
      <SimpleModal
        isOpen={isConfirmationModalOpen}
        title={'Confirmation'}
        body={infoMessage}
        buttonText={'Ok'}
        buttonCancelText={'Cancel'}
        onButtonClick={() => this.onDeleteClick(item, isAlbum)}
        onCloseClick={this.hideConfirmationModal}
      />
    );
  }

  hideConfirmationModal = () => {
    this.setState({
      isConfirmationModalOpen: false,
    });
  };

  onCancelButtonClick = () => {
    this.setState({
      isCreateDirFormModalOpen: false,
    });
  };

  showCreateDirFormModal = () => {
    this.setState({
      isCreateDirFormModalOpen: true,
    });
  };

  createAlbum(albumBucketName) {
    const { bucketName } = this.state;
    const s3 = this.getBucketCredentials();
    const albumName = albumBucketName.split(' ').join(' ');
    if (!albumName) {
      const infoMessage = 'Album names must contain at least one non-space character.';
      return this.showSendingInfoModal(infoMessage);
    }
    if (albumName.indexOf('/') !== -1) {
      const infoMessage = 'Album names cannot contain slashes.';
      return this.showSendingInfoModal(infoMessage);
    }
    this.setState({
      isLoading: true,
    });
    const key = propz.get(this.state, ['activeAlbum', 'Key'], '');
    const albumKeyWithSlash = key[key.length - 1] !== '/' ? key + '/' : key;
    const albumKeyWithFirstSlash = albumKeyWithSlash[0] === '/' ? albumKeyWithSlash.slice(1) : albumKeyWithSlash;
    const pathAlbum = albumKeyWithFirstSlash + albumBucketName;

    s3.headObject({ Key: pathAlbum + '/', Bucket: bucketName }, (err, data) => {
      if (!err) {
        const infoMessage = 'Album already exists.';
        return this.showSendingInfoModal(infoMessage);
      }
      if (err.code !== 'NotFound') {
        const infoMessage = 'There was an error creating your album: ' + err.message;
        return this.showSendingInfoModal(infoMessage);
      }
      s3.putObject({ Key: pathAlbum + '/', Bucket: bucketName }, (err, data) => {
        if (err) {
          const infoMessage = 'There was an error creating your album: ' + err.message;
          return this.showSendingInfoModal(infoMessage);
        }
        if (key === '') {
          this.getFullAlbums('/');
        } else {
          this.onSchoolAlbumClick(undefined, this.state.activeAlbum, albumKeyWithSlash);
        }
      });
      this.setState({
        isLoading: false,
      });
    });
    return true;
  }

  onSubmitCreateDirForm = value => {
    const albumBucketName = value.dirName;

    this.setState({
      isCreateDirFormModalOpen: false,
    });
    this.createAlbum(albumBucketName);
  };

  renderCreateDirFormModal(): React.ReactNode {
    const { isCreateDirFormModalOpen } = this.state;

    return (
      <SimpleModal isOpen={isCreateDirFormModalOpen}>
        <SchoolAppUploadFilesViewForm2 onCancel={this.onCancelButtonClick} onSubmit={this.onSubmitCreateDirForm} />
      </SimpleModal>
    );
  }

  addPhoto(acceptedFile) {
    const file = acceptedFile;
    const fileName = acceptedFile.name;

    const { activeAlbum, bucketName } = this.state;
    const key = propz.get(activeAlbum, ['Key'], '');
    const albumPhotosKey =
      typeof activeAlbum !== 'undefined' && activeAlbum.type === BUCKET_DATA_TYPE.FILE ? key + '/' : key;
    const photoKey =
      albumPhotosKey[albumPhotosKey.length - 1] !== '/' ? albumPhotosKey + '/' + fileName : albumPhotosKey + fileName;

    // Use S3 ManagedUpload class as it supports multipart uploads
    const upload = new AWS.S3.ManagedUpload({
      params: {
        Bucket: bucketName,
        Key: photoKey,
        Body: file,
      },
    });

    const promise = upload.promise();

    promise.then(
      data => {
        this.onSchoolAlbumClick(undefined, activeAlbum, activeAlbum.Key);
      },
      err => {
        const infoMessage = `There was an error uploading your file: ${err.message}`;
        return this.showSendingInfoModal(infoMessage);
      }
    );
  }

  onFolderDownloadClick(event, album) {
    event.preventDefault();
    const { bucketName } = this.state;
    const s3 = this.getBucketCredentials();
    this.setState({
      isLoading: true,
    });
    const folderName = propz.get(album, ['name'], 'file');
    let files = [];
    const zip = new JSZip();

    const getObject = file => {
      return new BPromise((resolve, reject) => {
        s3.getObject({ Key: file, Bucket: bucketName }, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    };

    const getListObjects = () => {
      return new BPromise((resolve, reject) => {
        s3.listObjects({ Delimiter: album.Key, Prefix: album.Key, Bucket: bucketName }, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
    };

    let fileData;

    getListObjects()
      .then(
        data => {
          fileData = (data as any).Contents.filter(content => {
            const key = propz.get(content, ['Key'], '');
            const keyArr = [...key.split('/')];
            return keyArr[keyArr.length - 1] !== '';
          }).map(file => {
            const path = propz.get(file, ['Key'], '');

            return path;
          });

          return BPromise.all(
            fileData.map(file => {
              return getObject(file);
            })
          );
        },
        err => {
          this.setState({
            isLoading: false,
          });
          const infoMessage = 'There was an error viewing your album: ' + err.message;
          this.showSendingInfoModal(infoMessage);
        }
      )
      .then(files => {
        (files as Array<any>).forEach((file, index) => {
          const fileBlob = new Blob([file.Body], { type: file.ContentType });
          const filePath = fileData[index];
          const keyArr = [...filePath.split('/')];
          const fileName = keyArr[keyArr.length - 1];
          zip.file(`${fileName}`, fileBlob);
        });

        zip.generateAsync({ type: 'blob' }).then(content => {
          saveAs(content, `${folderName}.zip`);
          this.setState({
            isLoading: false,
          });
        });
      });
  }

  onFileDownloadClick(event, album) {
    if (typeof event !== 'undefined') {
      event.preventDefault();
    }
    const { bucketName } = this.state;
    const s3 = this.getBucketCredentials();
    this.setState({
      isLoading: true,
    });
    const key = propz.get(album, ['Key'], '-');
    const keyArray = key.split('/');
    const fileName = keyArray[keyArray.length - 1];

    s3.getObject({ Key: key, Bucket: bucketName }, (err, data: any) => {
      if (err) {
        this.setState({
          isLoading: false,
        });
        const infoMessage = 'Failed to retrieve an object ' + err.message;
        return this.showSendingInfoModal(infoMessage);
      }
      this.setState({
        isLoading: false,
      });

      const xml = new Blob([data.Body], { type: data.ContentType });
      if (window.navigator.msSaveBlob) {
        // IE 10+
        window.navigator.msSaveBlob(xml, fileName);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          /*global URL:true*/
          const url = URL.createObjectURL(xml);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    });
  }

  showConfirmationModal = (event, index) => {
    event.preventDefault();

    this.setState({
      activeItemIndex: index,
      isConfirmationModalOpen: true,
    });
  };

  addPhotos(event) {
    const fileList = event.target.files;
    const files: any[] = Array.from(fileList);
    const { user } = this.props;
    BPromise.all(
      files.map(file => {
        this.setState({
          isLoading: true,
        });
        const fileName = file.name;

        const { activeAlbum, bucketName } = this.state;
        const key = propz.get(activeAlbum, ['Key'], '');
        const albumPhotosKey =
          typeof activeAlbum !== 'undefined' && activeAlbum.type === BUCKET_DATA_TYPE.FILE ? key + '/' : key;
        const photoKey =
          albumPhotosKey[albumPhotosKey.length - 1] !== '/'
            ? albumPhotosKey + '/' + fileName
            : albumPhotosKey + fileName;

        // Use S3 ManagedUpload class as it supports multipart uploads
        const result = new AWS.S3.ManagedUpload({
          params: {
            Bucket: bucketName,
            Key: photoKey,
            Body: file,
          },
        }).promise();

        return result;
      })
    )
      .then(() => {
        return sendUploadFileNotification(
          user,
          files.map(file => file.name)
        );
      })
      .then(res => {
        const { activeAlbum } = this.state;
        this.onSchoolAlbumClick(undefined, activeAlbum, activeAlbum.Key);
        const infoMessage = 'File has been uploaded';
        this.showSendingInfoModal(infoMessage);
      });
  }

  addFolder(event) {
    const fileList = event.target.files;
    const files: any[] = Array.from(fileList);
    const s3 = this.getBucketCredentials();
    const { activeAlbum, bucketName } = this.state;
    const key = propz.get(activeAlbum, ['Key'], '');
    this.setState({
      isLoading: true,
    });

    if (files.length > 0) {
      const putObject = date => {
        return new BPromise((resolve, reject) => {
          s3.putObject(date, (err, data) => {
            if (err) {
              reject(err);
            } else {
              resolve(data);
            }
          });
        });
      };
      BPromise.all(
        files.map(file => {
          const { activeAlbum, bucketName } = this.state;
          const key = propz.get(activeAlbum, ['Key'], '');
          const filePath = file.webkitRelativePath.split('/');

          const albumNames = [...filePath];
          albumNames.splice(filePath.length - 1, 1);
          let fullPath = albumNames[0];

          const albumPromises = albumNames.map((name, index) => {
            if (index > 0) {
              fullPath = fullPath + '/' + name;
            }
            const key = propz.get(this.state, ['activeAlbum', 'Key'], '');
            const albumKeyWithSlash = key[key.length - 1] !== '/' ? key + '/' : key;
            const pathAlbum = albumKeyWithSlash + fullPath;

            return putObject({ Key: pathAlbum + '/', Bucket: bucketName });
          });

          return BPromise.all(albumPromises).then(albums => {
            const fileName =
              file.webkitRelativePath[0] === '/' ? file.webkitRelativePath.slice(1) : file.webkitRelativePath;
            const albumPhotosKey =
              typeof activeAlbum !== 'undefined' && activeAlbum.type === BUCKET_DATA_TYPE.FILE ? key + '/' : key;
            const photoKey =
              albumPhotosKey[albumPhotosKey.length - 1] !== '/'
                ? albumPhotosKey + '/' + fileName
                : albumPhotosKey + fileName;

            // Use S3 ManagedUpload class as it supports multipart uploads
            const params = {
              Bucket: bucketName,
              Key: photoKey,
              Body: file,
            };
            const upload = new AWS.S3.ManagedUpload({ params: params });

            return new BPromise((resolve, reject) => {
              upload.send((err, data) => {
                if (err) {
                  reject(err);
                } else {
                  resolve(data);
                }
              });
            });
          });
        })
      ).then(res => {
        this.setState({
          isLoading: false,
        });
        const infoMessage = 'File has been uploaded';
        this.showSendingInfoModal(infoMessage);
        const { activeAlbum } = this.state;
        this.onSchoolAlbumClick(undefined, activeAlbum, activeAlbum.Key);
      });
    } else {
      this.setState({
        isLoading: false,
      });
      const infoMessage = 'The folder is empty';
      this.showSendingInfoModal(infoMessage);
    }
  }

  setAcceptedFiles = acceptedFiles => {
    const s3 = this.getBucketCredentials();
    const { activeAlbum, bucketName } = this.state;
    const key = propz.get(activeAlbum, ['Key'], '');
    this.setState({
      isLoading: true,
    });
    BPromise.all(
      acceptedFiles.map(file => {
        const isFileInFolder = file.path[0] === '/';
        if (isFileInFolder) {
          const filePath = file.path.split('/');

          const albumNames = [...filePath];
          albumNames.splice(filePath.length - 1, 1);
          albumNames.splice(0, 1);

          let fullPath = albumNames[0];
          BPromise.all(
            albumNames.map((name, index) => {
              if (index > 0) {
                fullPath = fullPath + '/' + name;
              }
              const key = propz.get(this.state, ['activeAlbum', 'Key'], '');
              const albumKeyWithSlash = key[key.length - 1] !== '/' ? key + '/' : key;
              const pathAlbum = albumKeyWithSlash + fullPath;

              s3.putObject({ Key: pathAlbum + '/', Bucket: bucketName }, (err, data) => {
                if (err) {
                  const infoMessage = 'There was an error creating your album: ' + err.message;
                  return this.showSendingInfoModal(infoMessage);
                }
              });
            })
          ).then(res => {
            const fileName = file.path[0] === '/' ? file.path.slice(1) : file.path;
            const albumPhotosKey =
              typeof activeAlbum !== 'undefined' && activeAlbum.type === BUCKET_DATA_TYPE.FILE ? key + '/' : key;
            const photoKey =
              albumPhotosKey[albumPhotosKey.length - 1] !== '/'
                ? albumPhotosKey + '/' + fileName
                : albumPhotosKey + fileName;
            // Use S3 ManagedUpload class as it supports multipart uploads
            const upload = new AWS.S3.ManagedUpload({
              params: {
                Bucket: bucketName,
                Key: photoKey,
                Body: file,
              },
            });
            const promise = upload.promise();
            promise.then(
              data => {},
              err => {
                const infoMessage = `There was an error uploading your file: ${err.message}`;
                return this.showSendingInfoModal(infoMessage);
              }
            );
          });
        } else {
          this.addPhoto(file);
        }
      })
    ).then(res => {
      this.onSchoolAlbumClick(undefined, activeAlbum, activeAlbum.Key);
      const infoMessage = 'File has been uploaded';
      this.showSendingInfoModal(infoMessage);
    });
  };

  onBackClick = (event, newKey) => {
    event.preventDefault();

    this.setState({
      isLoading: true,
    });
    const activeAlbum = { ...this.state.activeAlbum };
    if (newKey !== '') {
      activeAlbum.Key = newKey;
      this.onSchoolAlbumClick(undefined, activeAlbum, activeAlbum.Key);
    } else {
      this.getFullAlbums('/');
    }
  };

  renderTable = () => {
    const { albumsAndPhotos, activeAlbum, activePrefix, isAdminToHaveSchool } = this.state;
    const rows = albumsAndPhotos.map((album, index) => {
      const name = propz.get(album, ['name'], '-');

      const lastModified = propz.get(album, ['LastModified']);
      const lastModifiedDate =
        typeof lastModified !== 'undefined' ? Moment(new Date(lastModified)).format(DATE_TIME_FORMAT) : '-';
      const fileSize = propz.get(album, ['Size'], 0);
      const fileGivenSize = fileSize > 0 ? `${(fileSize / 1000).toFixed(0)} Kb` : '-';
      const isAlbum = album.type === BUCKET_DATA_TYPE.ALBUM;
      const key = propz.get(album, ['Key'], '');
      const albumPhotosKey =
        typeof activeAlbum !== 'undefined' && activeAlbum.type === BUCKET_DATA_TYPE.FILE ? key + '/' : key;
      const activeAlbumKey = propz.get(activeAlbum, ['Key'], '');

      const activePrefixArray = [...activePrefix.split('/')];
      const isSchoolAccount = true;
      const keyArray = [...activeAlbumKey.split('/')];
      keyArray.splice(keyArray.length - 2, 1);
      const isNoActiveSchoolAlbumName = isSchoolAccount
        ? activePrefixArray.length > 0 && activePrefixArray[0] !== ''
        : activePrefix !== '';

      return (
        <tr key={`album_${index}`}>
          <td>
            {isAlbum ? (
              <div className="eUploadedFiles">
                <a className="" href="" onClick={e => this.onSchoolAlbumClick(e, album, albumPhotosKey)}>
                  <svg
                    width="2em"
                    height="2em"
                    viewBox="0 0 16 16"
                    className="bi bi-folder2-open eUploadedFilesField"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1 3.5A1.5 1.5 0 0 1 2.5 2h2.764c.958 0 1.76.56 2.311 1.184C7.985 3.648 8.48 4 9 4h4.5A1.5 1.5 0 0 1 15 5.5v.64c.57.265.94.876.856 1.546l-.64 5.124A2.5 2.5 0 0 1 12.733 15H3.266a2.5 2.5 0 0 1-2.481-2.19l-.64-5.124A1.5 1.5 0 0 1 1 6.14V3.5zM2 6h12v-.5a.5.5 0 0 0-.5-.5H9c-.964 0-1.71-.629-2.174-1.154C6.374 3.334 5.82 3 5.264 3H2.5a.5.5 0 0 0-.5.5V6zm-.367 1a.5.5 0 0 0-.496.562l.64 5.124A1.5 1.5 0 0 0 3.266 14h9.468a1.5 1.5 0 0 0 1.489-1.314l.64-5.124A.5.5 0 0 0 14.367 7H1.633z"
                    />
                  </svg>
                  <div className="ml-3 eUploadedFilesName">{name}</div>
                </a>
              </div>
            ) : (
              <div className="eUploadedFiles">
                <svg
                  width="2em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-file-earmark eUploadedFilesField"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M4 1h5v1H4a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V6h1v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z" />
                  <path d="M9 4.5V1l5 5h-3.5A1.5 1.5 0 0 1 9 4.5z" />
                </svg>
                <div className="ml-3 eUploadedFilesName">{name}</div>
              </div>
            )}
          </td>
          <td>{lastModifiedDate}</td>
          <td>{fileGivenSize}</td>
          <td>
            <div className="eUploadedFiles">
              <a
                className=""
                href=""
                onClick={e => {
                  if (isAlbum) {
                    this.onFolderDownloadClick(e, album);
                  } else {
                    this.onFileDownloadClick(e, album);
                  }
                }}
              >
                <svg
                  width="2em"
                  height="2em"
                  viewBox="0 0 16 16"
                  className="bi bi-download"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
                  />
                </svg>
              </a>
            </div>
          </td>
          <td>
            {isNoActiveSchoolAlbumName && !isSchoolAccount && (
              <div className="eUploadedFiles">
                <a className="" href="" onClick={e => this.showConfirmationModal(e, index)}>
                  <svg
                    width="2em"
                    height="2em"
                    viewBox="0 0 16 16"
                    className="bi bi-trash"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fillRule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </a>
              </div>
            )}
          </td>
        </tr>
      );
    });
    const emptyRow = (
      <tr key={'events_empty_row'}>
        <td>The folder is empty</td>
      </tr>
    );
    const key = propz.get(activeAlbum, ['Key'], '');
    const activePrefixArray = [...activePrefix.split('/')];
    const isSchoolAccount = true;
    const keyArray = [...key.split('/')];
    keyArray.splice(keyArray.length - 2, 1);
    const newKey = keyArray.join('/');
    const isNoEmptyActiveAlbumName = typeof activePrefix !== 'undefined' && activePrefix !== '';
    const isNoActiveSchoolAlbumName = isSchoolAccount
      ? activePrefixArray.length > 1 && activePrefixArray[1] !== ''
      : activePrefix !== '';
    const albumPhotosKey =
      typeof activeAlbum !== 'undefined' && activeAlbum.type === BUCKET_DATA_TYPE.FILE ? key + '/' : key;

    const actionItems = isSchoolAccount
      ? [
          {
            itemText: 'Upload files',
            onItemClick: () => this.myRefUploadFile.current.click(),
            isActive: isNoEmptyActiveAlbumName,
          },
          // {
          //   itemText: 'Upload folder',
          //   onItemClick: () => this.myRefUploadFolder.current.click(),
          //   isActive: isNoEmptyActiveAlbumName,
          // },
        ]
      : [
          {
            itemText: 'Upload files',
            onItemClick: () => this.myRefUploadFile.current.click(),
            isActive: isNoEmptyActiveAlbumName,
          },
          // {
          //   itemText: 'Upload folder',
          //   onItemClick: () => this.myRefUploadFolder.current.click(),
          //   isActive: isNoEmptyActiveAlbumName,
          // },
          // {
          //   itemText: 'Create folder',
          //   onItemClick: () => this.showCreateDirFormModal(),
          //   isActive: isNoEmptyActiveAlbumName,
          // },
        ];
    const pointerText = albumPhotosKey.split('/').join('  >  ');

    return (
      <>
        <div className="h2 font-weight-bold">Data Exchange Room</div>
        {isAdminToHaveSchool ? (
          <>
            <div className="d-flex justify-content-start">
              <Button
                onClick={e => this.onBackClick(e, newKey)}
                text={'Back'}
                customClass={'mt-3 mb-3 mr-3 btn-secondary'}
                disabled={!isNoActiveSchoolAlbumName}
              />
              <div className="h4 mt-3 mb-3">{pointerText}</div>
            </div>
            <div className="mb-3">
              <ActionsButton actionItems={actionItems} />
            </div>
            <input
              ref={this.myRefUploadFolder}
              type="file"
              hidden
              /* since webkitdirectory is not an official attribute yet */
              // @ts-ignore: Unreachable code error
              directory=""
              webkitdirectory=""
              multiple
              onChange={e => this.addFolder(e)}
            />
            <input ref={this.myRefUploadFile} type="file" multiple hidden onChange={e => this.addPhotos(e)} />

            <div>
              <Dropzone onDrop={acceptedFiles => this.setAcceptedFiles(acceptedFiles)}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input hidden />
                      <table className={'table table-sm mPre'}>
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Last modified</th>
                            <th>File size</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>{rows.length > 0 ? rows : emptyRow}</tbody>
                      </table>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </>
        ) : (
          <h3 className="mt-5">No folder has been added for your school yet.</h3>
        )}
      </>
    );
  };

  render() {
    const { isLoading, isCreateDirFormModalOpen, isConfirmationModalOpen, isInfoModalOpen } = this.state;

    if (isLoading) {
      return <Loader />;
    }
    const classes = isCreateDirFormModalOpen || isConfirmationModalOpen || isInfoModalOpen ? 'mt-3 modal-open' : 'mt-3';

    return (
      <div className={classes}>
        {this.renderSendingInfo()}
        {this.renderConfirmationModal()}
        {this.renderCreateDirFormModal()}
        {this.renderTable()}
      </div>
    );
  }
}
