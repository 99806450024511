import * as React from 'react';
import * as propz from 'propz';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import './ImagePhotoSlider.scss';
import { Component } from 'react';
import * as classNames from 'classnames';
import { isImageGroup, isImageIndividual, isImagePanoramic } from '../../../../../helpers/images';

interface Props {
  image: any;
  imageIndex: number;
  selectedImageId: any;
  onImageClick: (selectedTicket: any) => void;
  showConfirmationModal?: (index) => void;
  isDeleteIconVisible: boolean;
}

interface State {
  cssClassSizeModal: string;
  isFullSizeModalOpen: boolean;
  isBigSizeModalOpen: boolean;
}

export class ImagePhotoSlider extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cssClassSizeModal: '',
      isFullSizeModalOpen: false,
      isBigSizeModalOpen: false,
    };
  }

  componentDidMount() {
    const { image } = this.props;
    switch (true) {
      case isImageIndividual(image.photoTypeName):
      case isImageGroup(image.photoTypeName):
      default:
        this.setState({
          cssClassSizeModal: 'modal-xs',
        });
        break;
      case isImagePanoramic(image.photoTypeName):
        this.setState({
          cssClassSizeModal: 'modal-xl',
        });
        break;
    }
  }

  onFullSizeImageClose = event => {
    event.preventDefault();
    this.setState({
      isFullSizeModalOpen: false,
    });
  };

  onFullSizeImageOpen = event => {
    event.preventDefault();

    this.setState({
      isFullSizeModalOpen: true,
    });
  };

  onBigSizeImageClose = event => {
    event.preventDefault();
    this.setState({
      isBigSizeModalOpen: false,
    });
  };

  onBigSizeImageOpen = event => {
    event.preventDefault();

    this.setState({
      isBigSizeModalOpen: true,
    });
  };

  renderBigSizeModal = () => {
    const { isBigSizeModalOpen, cssClassSizeModal } = this.state;
    const { image } = this.props;

    const modalClasses = classNames({
      mDisplayBlock: isBigSizeModalOpen,
      show: isBigSizeModalOpen,
      modal: true,
      fade: true,
    });

    return (
      <div className={modalClasses}>
        <div className={`modal-dialog modal-dialog-centered ${cssClassSizeModal}`}>
          <div className="modal-content">
            <div className="modal-header p-0">
              <div className="modal-body p-0">
                <img
                  src={`${window.apiFile}/storage/images/${image.ticketId}`}
                  alt="fullImages"
                  className="w-100"
                  onClick={this.onBigSizeImageClose}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderFullSizeModal = () => {
    const { isFullSizeModalOpen } = this.state;
    const { image } = this.props;

    const modalClasses = classNames({
      mDisplayBlock: isFullSizeModalOpen,
      show: isFullSizeModalOpen,
      mOverflowAuto: true,
      modal: true,
      fade: true,
    });

    return (
      <div className={modalClasses}>
        <img
          src={`${window.apiFile}/storage/images/${image.ticketId}`}
          alt="fullImages"
          className="eFullSizeImage"
          onClick={this.onFullSizeImageClose}
        />
      </div>
    );
  };

  onDeleteImageClick = index => {
    const { showConfirmationModal } = this.props;
    showConfirmationModal(index);
  };

  render() {
    const { image, imageIndex, selectedImageId, onImageClick, isDeleteIconVisible } = this.props;
    const { isFullSizeModalOpen, isBigSizeModalOpen } = this.state;
    const classname = propz.get(image, ['classname'], '');
    const imageRef = propz.get(image, ['bulkImageId'], '');
    const classes = isFullSizeModalOpen || isBigSizeModalOpen ? 'modal-open' : '';
    return (
      <div key={image.imageId} className={classes}>
        {this.renderFullSizeModal()}
        {this.renderBigSizeModal()}
        <div
          className={
            selectedImageId === image.imageId
              ? 'ePhotoSliderImageWrapper mPhotoSliderSelected'
              : 'ePhotoSliderImageWrapper'
          }
          key={image.ticketId}
          onClick={() => {
            onImageClick(image);
          }}
        >
          <img className="ePhotoSliderImage" src={`${window.apiFile}/storage/images/${image.ticketId}`} />
          <div className="">
            <img
              className="eSliderFullImageIcon"
              width="20px"
              src="/dist/images/icon/zoomIn.png"
              onClick={this.onFullSizeImageOpen}
            />
            {isDeleteIconVisible && (
              <img
                className="eSliderRemoveImageIcon"
                width="20px"
                src="/dist/images/icon/dustbin.png"
                onClick={() => this.onDeleteImageClick(imageIndex)}
              />
            )}
          </div>
          <div className="ePhotoSliderImageTitle h6">{classname}</div>
          <div className="ePhotoSliderImageTitle h6">Image ref: {imageRef}</div>
        </div>
      </div>
    );
  }
}
