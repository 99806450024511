import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Schools } from './Schools';
import { SchoolForms } from './SchoolForms/SchoolForms';
import { SchoolStudents } from './SchoolStudents/SchoolStudents';
import { SchoolUsers } from './SchoolUsers/SchoolUsers';

export const SchoolsRouting = allProps => {
  return (
    <Switch>
      <Route exact path={'/schools'} render={props => <Schools {...props} user={allProps.user} />} />
      <Route exact path={'/schools/forms'} render={props => <SchoolForms {...props} user={allProps.user} />} />
      <Route exact path={'/schools/students'} render={props => <SchoolStudents {...props} user={allProps.user} />} />
      <Route exact path={'/schools/schoolUsers'} render={props => <SchoolUsers {...props} user={allProps.user} />} />
    </Switch>
  );
};

SchoolsRouting.displayName = 'SchoolsRouting';
