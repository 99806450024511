import * as React from 'react';
import { Component } from 'react';
import './SchoolAppSidebar2.css';
import { SchoolAppSidebarItem2 } from './SchoolAppSidebarItem2';
import { AppSchoolsUser2 } from '../AppSchools2';

interface Props {
  user: AppSchoolsUser2;
}

interface State {}

export interface SimpleSidebarItem2 {
  to: {
    pathname: string;
  };
  itemTitle: string;
}

export class SchoolAppSidebar2 extends Component<Props, State> {
  constructor(props) {
    super(props);
  }

  getSidebarItems(): SimpleSidebarItem2[] {
    const { user } = this.props;
    const { isSchoolAdmin } = user;
    return isSchoolAdmin
      ? [
          {
            itemTitle: 'Data Exchange Room',
            to: { pathname: '/upload' },
          },
          {
            itemTitle: 'Users',
            to: { pathname: '/users' },
          },
          {
            itemTitle: 'Jobs',
            to: { pathname: '/schoolJobs' },
          },
        ]
      : [
          {
            itemTitle: 'Data Exchange Room',
            to: { pathname: '/upload' },
          },
        ];
  }

  render() {
    const sidebarItems: SimpleSidebarItem2[] = this.getSidebarItems();

    return (
      <div className="bSidebar">
        {sidebarItems.map((sidebarItem, index) => {
          return <SchoolAppSidebarItem2 key={`sidebar_item_${index}`} item={sidebarItem} />;
        })}
      </div>
    );
  }
}
